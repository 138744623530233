import { MDXProvider } from "@mdx-js/react";
import React from "react";
import './src/styles/global.css';

const c = {
  h1: ({ children }) => <h1 className="text-4xl font-bold p-2">{children}</h1>,
  h2: ({ children }) => <h2 className="text-2xl font-bold p-2">{children}</h2>,
  p: ({ children }) => <p className="p-5">{children}</p>,
  ul: ({ children }) => <ul className="text-base pl-5 pr-5 p-2">{children}</ul>,
  li: ({ children }) => <li className="text-base p-1">- {children}</li>,
  img: ({ children }) => <img className="image p-2">- {children}</img>,
};

export const wrapper = ({ element, props }) => (
  <MDXProvider {...props} components={c}>{element}</MDXProvider>
);
